import { EducationDetail, ResumeData, SkillsInfo, WorkDetail } from "../types/resumeData";
import { PersonalDetails } from "./personalDetails";


const educationData: EducationDetail[] = [
    {
        id: 1,
        qualification: 'Bachelor of Technology',
        stream: 'Electroins and Communications Engineering',
        college: 'Anurag Group of Institutions',
        address: 'RangaReddy, Telangana, India',
        from: 2014,
        to: 2018
    },
    {
        id: 2,
        qualification: 'Class 11th - 12th',
        college: 'Narayana Junior College',
        address: 'RangaReddy, Telangana, India',
        from: 2012,
        to: 2014
    },
    {
        id: 3,
        qualification: 'class 10th',
        college: 'Bhashyam Public School',
        address: 'Guntur, Andhra Pradesh, India',
        from: 2011,
        to: 2012
    }
]

const workData: WorkDetail[] = [
    {
        id: 1,
        company: 'OwnBackup',
        designation: 'Software Engineer',
        city: 'Hyderabad, Telangana, India',
        fromDate: new Date('2021-05-01')
    },
    {
        id: 2,
        company: 'Nimmetry Inc.',
        designation: 'Software Engineer',
        city: 'Hyderabad, Telangana, India',
        fromDate: new Date('2018-09-05'),
        toDate: new Date('2021-04-30')
    },
]

const primarySkills: SkillsInfo[] = [
    {
        name: 'Python',
        confidence: 80
    },
    {
        name: 'Javascript',
        confidence: 85
    },
    {
        name: 'Typescript',
        confidence: 85
    }
]

const secondarySkills: SkillsInfo[] = [
    {
        name: 'Angular'
    },
    {
        name: 'React.js'
    },
    {
        name: 'HTML'
    },
    {
        name: 'CSS'
    },
    {
        name: 'Node.js'
    },
    {
        name: 'MongoDB'
    },
    {
        name: 'ServiceNow'
    },
]

export const ResumeDetails: ResumeData = {
    name: PersonalDetails.name,
    education: educationData,
    work: workData,
    skills: primarySkills,
    secondarySkills: secondarySkills,
}

export const PRIMARY_SKILLS_HEADING = 'Skills';
export const SECONDARY_SKILLS_HEADING = 'Knowledges';
