import { Link } from 'react-router-dom';
import ListItemButton from "@mui/material/ListItemButton";
import { styled } from "@mui/material/styles";
import Avatar from '@mui/material/Avatar';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';


export const StyledAppBar = styled(AppBar)<{trigger: string}>(({trigger}) => ({
    background: trigger === 'true' ? "#121212" : "transparent",
    boxShadow: trigger === 'true' ? '5px 0px 27px -5px rgba(0, 0, 0, 0.3)' : 'none',
    lineHeight:'132px',
    verticalAlign: 'middle'
}));


export const AppBarBox = styled(Box)(({ theme }) => ({
    display: 'block',
    [theme.breakpoints.down('md')]: {
        display: 'none'
    }
}));


export const StyledDrawer = styled(Drawer)(({ theme }) => ({
    display: 'none',
    [theme.breakpoints.down('md')]: {
        display: 'block'
    },
    '& .MuiDrawer-paper': { boxSizing: 'border-box' },
}));


export const NavbarListItemButton = styled(ListItemButton)<{active?: string}>(({ active }) => ({
    color: active === 'true' ? '#0093E9' : '#FFFFFF',
    '.MuiListItemIcon-root': {
        color: active === 'true' ? '#0093E9' : '#FFFFFF',
    },
    '&:hover': {
        color: '#0093E9',
        '.MuiListItemIcon-root': {
            color: '#0093E9'
        }
    }
}));


export const RouterLink = styled(Link)`
    text-decoration: none;
    color: #ffffff;
    padding: 7px;
    width: 100%
`


export const ProfileAvatarSpan = styled('span')(() => ({
    display: 'inline-flex',
    alignItems: 'center'
}))


export const ProfileAvatar = styled(Avatar)(({theme}) => ({
    height: '56px',
    width: '56px',
    borderRadius: '50%',
    backgroundColor: '#0093E9',
    backgroundImage: 'linear-gradient(270deg, #0093E9 0%, #80D0C7 100%)',
    verticalAlign: 'middle',
    marginRight: theme.spacing(2)
}))
