import { AppTheme } from './helpers/appTheme';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Navigate, useRoutes } from 'react-router-dom';
import { ABOUT_ME_ROUTE, CONTACT_ROUTE, HOME_ROUTE, RESUME_ROUTE, SLASH_ROUTE } from './constants/appRoutes';
import Navbar from './components/navbar/Navbar';
import Home from './components/home/Home';
import AboutMe from './components/about-me/AboutMe';
import Resume from './components/resume/Resume';
import Contact from './components/contact/Contact';


function App() {
  const AppRoutes = () => useRoutes([
    { path: SLASH_ROUTE, element: <Navigate to={HOME_ROUTE} /> },
    { path: HOME_ROUTE, element: <Home/> },
    { path: ABOUT_ME_ROUTE, element: <AboutMe /> },
    { path: RESUME_ROUTE, element: <Resume /> },
    { path: CONTACT_ROUTE, element: <Contact /> }
  ]);


  return (
    <ThemeProvider theme={AppTheme}>
      <CssBaseline />
      <div className="App">
        <Navbar key="app_navbar" appRoutes={<AppRoutes />}/>
      </div>
    </ThemeProvider>
  );
}

export default App;
