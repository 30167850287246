import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { AppCardProps } from '../../../types/props/appCardProps';
import { StyledCardContent } from './appCardStyles';


export default function AppCard(props: AppCardProps) {
  return (
    <Card sx={{ borderRadius: '16px' }} key={`card_${props.title}`}>
      <StyledCardContent>
        <Container maxWidth='lg' sx={{ padding: '48px 16px' }}>
          <Typography variant="h4" component="div" sx={{ fontSize: '40px', marginBottom: '40px', fontWeight: 700 }}>
            { props.title }
          </Typography>
          <Typography variant="body1" component="div" sx={{ paddingLeft: '15px' }}>
            { props.body }
          </Typography>
        </Container>
      </StyledCardContent>
    </Card>
  );
}