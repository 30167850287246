import { createTheme } from '@mui/material/styles';
import { DarkTheme } from '../constants/appThemePalette';

export const AppTheme = createTheme({
  typography: {
    fontFamily: [
      'Poppins',
      'sans-serif'
    ].join(',')
  },
  palette: {
    mode: 'dark',
    primary: {
      main: DarkTheme.colors.primary
    }
  }
});
