import { primaryInfo } from "./personalInfo";
import { PersonalInfoData } from "../types/aboutMeData";
import PhonelinkRingOutlinedIcon from '@mui/icons-material/PhonelinkRingOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CelebrationOutlinedIcon from '@mui/icons-material/CelebrationOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';


const month = `${primaryInfo.birthday.toLocaleString('default', { month: 'short' })}`;
const date = `${primaryInfo.birthday.getDate()}`;
const year = `${primaryInfo.birthday.getFullYear()}`;

export const ABOUT_ME_DESCRIPTION_SECTION_HEADING = 'Who am I..?';
export const ABOUT_ME_PERSONAL_DETAILS_SECTION_HEADING = 'Personal Info...'


export const personalInfoData: PersonalInfoData[] = [
    // {
    //     name: 'Phone',
    //     value: primaryInfo.mobileNumber,
    //     icon: PhonelinkRingOutlinedIcon
    // },
    {
        name: 'Location',
        value: primaryInfo.location,
        icon: LocationOnOutlinedIcon
    },
    {
        name: 'Email',
        value: primaryInfo.emailId,
        icon: EmailOutlinedIcon
    },
    // {
    //     name: 'Birthday',
    //     value: `${month} ${date}, ${year}`,
    //     icon: CelebrationOutlinedIcon
    // },
    
]


export const aboutMeDescription = [
    "I am a software developer based in Hyderabad, India. I am really passionate about technology and enjoys solving complex problems. My work involves extraction of data efficiently for backup and restore. As a lover of fictional and crime-thriller stories, I am always eager to watch movies and TV shows and play video games in my free time.",

    "My aim is to build innovative projects that create a positive impact."
]
