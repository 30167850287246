import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { PRIMARY_SKILLS_HEADING, ResumeDetails, SECONDARY_SKILLS_HEADING } from "../../../data/resumeDetails";
import AppProgressBar from "../../common/app-progress-bar/AppProgressBar";
import { SecondaryDetailsChip, SecondaryDetailsGrid } from "../resumeStyles";


export default function ResumeSecondaryDetails() {
    return(
        <SecondaryDetailsGrid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <h2 style={{ marginBottom: 0 }}> { PRIMARY_SKILLS_HEADING } </h2>
                <div style={{ paddingRight: '10px' }}>
                    {
                        ResumeDetails.skills.map((skill) => 
                            <div key={`progress_box_${skill.name}`}>
                                <AppProgressBar appKey={`${skill.name}`} label={ skill.name } progress={ skill.confidence || 0 } isDeterminate={true}/>
                            </div>
                        )
                    }
                </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <h2> { SECONDARY_SKILLS_HEADING } </h2>
                <Stack direction="row">
                    <div>
                    {
                        ResumeDetails.secondarySkills.map((skill) =>
                            <SecondaryDetailsChip key={`${skill.name}`} label={skill.name} />
                        )
                    }
                    </div>
                </Stack>
            </Grid>
        </SecondaryDetailsGrid>
        
    )
}
