import { styled } from "@mui/material/styles";
import Button  from "@mui/material/Button";


export const StyledDiv = styled('div')(() => ({
    textAlign: 'center'
}));

export const ProfileBorderDiv = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '300px',
    width: '300px',
    borderRadius: '50%',
    backgroundColor: '#0093E9',
    backgroundImage: 'linear-gradient(270deg, #0093E9 0%, #80D0C7 100%)',
    boxShadow: '20px 20px 60px #212121, -20px -20px 60px #2d2d2d'
}))


export const ProfilePicDiv = styled('div')(() => ({
    height: '300px',
    width: '300px',
    borderRadius: '50%',
    border: 'double 4px transparent',
    backgroundColor: '#121212',
    // backgroundImage: 'linear-gradient(270deg, #0093E9 0%, #80D0C7 100%)',
    // boxShadow: '20px 20px 60px #212121, -20px -20px 60px #2d2d2d'
    backgroundOrigin: 'border-box',
    backgroundClip: 'padding-box, border-box'
}));


export const ProfileName = styled('h3')(() => ({
    margin: '24px 0 0',
    fontSize: '24px'
}));


export const Designation = styled('p')(() => ({
    margin: '4px 0 16px 0',
    color: '#7B7B7B',
    fontSize: '20px'
}));


export const SocialIconButton = styled('a')(() => ({
    '&:hover': {
        'svg': {
            fill: '#ffffff'
        },
        backgroundColor: '#0093E9',
        backgroundImage: 'linear-gradient(270deg, #0093E9 0%, #80D0C7 100%)'
    },
    display: 'inline-flex',
    justifyContent: 'center',
    width: '40px',
    color: '#ffffff',
    backgroundColor: '#272727',
    borderRadius: '50%',
    margin: '7px',
    'svg': {
        display: 'flex',
        alignItems: 'center',
        height: '40px',     
    }
}));


export const DownloadButton = styled(Button)(() => ({
    textTransform: 'none',
    position: 'relative',
    borderRadius: '35px',
    height: '48px',
    fontSize: '18px',
    border: '1px solid #0093E9',
    background: 'linear-gradient(to left, transparent 50%, #0093E9 0%, #80D0C7 100%) right',
    backgroundSize: '200%',
    transition: '.3s ease-out',
    '&:hover': {
        backgroundPosition: 'left',
        color: '#ffffff',
        '.MuiSvgIcon-root ': {
            color: 'white'
        }
    }
}));


export const DownloadButtonIcon = styled('span')(() => ({
    position: 'absolute',
    right: '76%',
    top: '0',
    width: '46px',
    height: '46px',
    background: '#80D0C7',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    '.MuiSvgIcon-root ': {
        color: '#000000',
        fontSize: '28px'
    }
}));
