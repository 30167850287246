import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";


export const PrimaryDetailsDiv = styled('div')(() => ({
    margin: '-2px 0 24px 12px',
    padding:'0 0 5px 20px',
    borderImage: 'linear-gradient(to top, #0093E9, #80D0C7) 1 100%',
    borderLeft: '2px solid #0093E9',
    position: 'relative',
    letterSpacing: '0.2px'
}));


export const PrimaryDetailsBorder = styled('div')(() => ({
    position: 'absolute',
    width: '16px',
    height: '16px',
    borderRadius: '50px',
    left: '-9px',
    top: '0',
    background: '#1E1E1E',
    border: '2px solid #80D0C7'
}));


export const PrimaryDetailsTitle = styled('p')(() => ({
    fontSize: '20px',
    margin: '0'
}));


export const PrimaryDetailSubtitle = styled('p')(() => ({
    fontSize: '13px',
    color: '#B7B7B7',
    margin: '0'
}));


export const PrimaryDetailsDesc = styled('p')(() => ({
    fontSize: '16px',
    color: '#B7B7B7',
    margin: '8px 0 0'
}));


export const PrimaryDetailsTimeline = styled('p')(() => ({
    fontSize: '14px',
    color: '#B7B7B7',
    margin: '10px 0 0'
}));


export const SecondaryDetailsGrid = styled(Grid)(() => ({
    marginTop: '20px',
    border: '2px solid rgba(0, 147, 233, 0.1)',
    borderRadius: '16px',
    paddingBottom: '30px',
    '&.MuiGrid-root>.MuiGrid-item': {
        paddingTop: '0'
    }
}));


export const SecondaryDetailsChip = styled(Chip)(() => ({
    color: '#A6A6A6',
    fontWeight: '700',
    fontSize: '16px',
    background: '#272727',
    border: '1px solid rgba(0, 147, 233, 0.4)',
    margin: '10px 5px',
    letterSpacing: '0.2px'
}));
