import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import { SocialNetwork } from '../types/socialNetwork';


const GradientInstagramIcon = () => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="instagramColors" x1={1} y1={0} x2={1} y2={1} gradientTransform="rotate(15)">
        <stop offset='0%' stopColor="#7637FA" />
        <stop offset='24%' stopColor="#D301C5" />
        <stop offset='54%' stopColor="#FF0069" />
        <stop offset='72%' stopColor="#FF7900" />
        <stop offset='100%' stopColor="#FED602" />
      </linearGradient>
    </svg>
    <InstagramIcon sx={{ fill: "url(#instagramColors)" }} />
  </>
)


export const SocialNetworks: SocialNetwork[] = [
  {
    name: 'Github',
    url: "https://github.com/nikhilavula",
    icon: <GitHubIcon />
  },
  {
    name: 'LinkedIn',
    url: "https://www.linkedin.com/in/nikhilsaikumar",
    icon: <LinkedInIcon sx={{ color: '#0177B5' }} />
  },
  {
    name: 'Twitter',
    url: "https://twitter.com/nikhil_avula997",
    icon: <TwitterIcon sx={{ color: '#1DA1F1' }} />
  },
  {
    name: 'Instagram',
    url: "https://www.instagram.com/anikhilsaikumar",
    icon: <GradientInstagramIcon />
  }
]
