import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { AppButtonProps } from '../../../types/props/appButtonProps';


const StyledButton = styled(Button)<{active: string, showborder: string, defaultcolor: string}>(({ theme, active, showborder, defaultcolor }) => ({
    textTransform: 'none',
    backgroundColor: active === 'true' ? '#0093E9' : '#272727',
    backgroundImage: active === 'true' ? 'linear-gradient(270deg, #0093E9 0%, #80D0C7 100%)' : 'none',
    '&:hover': {
        color: '#ffffff',
        border: 'none',
        backgroundColor: '#0093E9',
        backgroundImage: 'linear-gradient(270deg, #0093E9 0%, #80D0C7 100%)'
    },
    border: showborder === 'true' ? '1px solid #fff': 'none',
    borderRadius: '6px',
    color: active === 'true' ? '#ffffff' : defaultcolor,
    fontWeight: '600'
}));

export default function AppButton(props: AppButtonProps) {
    const [active, setActive] = useState(props.active);

    useEffect(() => {
        setActive(props.active);
    }, [props.active])

    return(
        <StyledButton variant='outlined' key={`${props.id}`} startIcon={props.startIcon}
            active={active.toString()} onClick={() => props.handleOnClick(props.name)}
            disabled={props.disabled || false} showborder={props.showBorder?.toString() || 'false'}
            defaultcolor={props.defaultColor || '#A6A6A6'}>
            {props.name}
        </StyledButton>
    )
}
