import Grid from "@mui/material/Grid";
import { DOWNLOAD_BUTTON_LABEL, PDF_FILE_NAME, PersonalDetails, RESUME_FILE_NAME_FOR_DOWNLOAD } from "../../data/personalDetails";
import { StyledDiv,ProfileBorderDiv, ProfilePicDiv, ProfileName, Designation, SocialIconButton, DownloadButton } from "./homeStyles";
import { SocialNetworks } from "../../data/SocialNetworks";
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';


export default function Home() {
    const downloadResume = () => {
        fetch(PDF_FILE_NAME).then(response => {
            response.blob().then(blob => {
                const fileURL = window.URL.createObjectURL(blob);
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = RESUME_FILE_NAME_FOR_DOWNLOAD.replace('fileName', PersonalDetails.displayName);
                alink.click();
            })
        })
    }

    return(
        <Grid container spacing={2} alignItems="center" justifyContent="center">
            <Grid item>
                <StyledDiv>
                    <ProfileBorderDiv>
                        <ProfilePicDiv>
                            <img style={{ borderRadius: '49%' }} height='292' width='280'
                                src={PersonalDetails.profilePicture} alt='profile-pic'/>
                        </ProfilePicDiv>
                    </ProfileBorderDiv>
                    <ProfileName>
                        {PersonalDetails.name}
                    </ProfileName>
                    <Designation>
                        {PersonalDetails.designation}
                    </Designation>
                    <div>
                        {
                            SocialNetworks.map((socialNetwork, idx) => 
                                <SocialIconButton key={`social_icon_${idx}`} target="_blank" rel="noreferrer" href={socialNetwork.url}>
                                    {socialNetwork.icon}
                                </SocialIconButton>
                            )
                        }
                    </div>
                    <div style={{ marginTop: '16px' }}>
                        <DownloadButton key='download_resume' variant='outlined' onClick={downloadResume}
                            startIcon={<FileDownloadRoundedIcon/>}>
                            <span>{DOWNLOAD_BUTTON_LABEL}</span>
                        </DownloadButton>
                    </div>
                </StyledDiv>
            </Grid>
        </Grid>
    )
}

// <DownloadButtonIcon><DownloadRoundedIcon/></DownloadButtonIcon>