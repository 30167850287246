import ResumePrimaryDetails from "./ResumePrimaryDetails";
import ResumeSecondaryDetails from "./ResumeSecondaryDetails";


export default function ResumeBody() {
    return (
        <div>
            <ResumePrimaryDetails />
            <ResumeSecondaryDetails />
        </div>
    )
}
