import { NavbarItem } from "../../types/navbarItem";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import { ABOUT_ME_ROUTE, CONTACT_ROUTE, HOME_ROUTE, RESUME_ROUTE } from "../../constants/appRoutes";


export const NavbarItems: NavbarItem[] = [
    {
        name: 'Home',
        active: false,
        route: HOME_ROUTE,
        icon: <HomeOutlinedIcon />
    },
    {
        name: 'About Me',
        active: false,
        route: ABOUT_ME_ROUTE,
        icon: <PersonOutlineOutlinedIcon />
    },
    {
        name: 'Resume',
        active: false,
        route: RESUME_ROUTE,
        icon: <ArticleOutlinedIcon/>
    },
    {
        name: 'Contact',
        active: false,
        route: CONTACT_ROUTE,
        icon: <ContactsOutlinedIcon/>
    }
]
