import { PersonalData } from "../types/personalData";
import ProfilePic from "../assets/images/profile-pic.png";
import ProfilePic2 from "../assets/images/profile-pic2.png";


export const PersonalDetails: PersonalData = {
    name: 'Nikhil Sai Kumar Reddy Avula',
    displayName: 'Nikhil Avula',
    designation: 'Software Engineer',
    profilePicture: ProfilePic,
    profilePicture2: ProfilePic2
}

export const DOWNLOAD_BUTTON_LABEL = 'Resume';
export const PDF_FILE_NAME = 'my-resume.pdf'
export const RESUME_FILE_NAME_FOR_DOWNLOAD = 'fileName Resume.pdf';
