import * as React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { PersonalDetails } from '../../data/personalDetails';
import { NavbarItems } from './NavbarItems';
import Container from '@mui/system/Container';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Grid from '@mui/material/Grid';
import ListItemIcon from '@mui/material/ListItemIcon';
import AppButton from '../common/app-button/AppButton';
import { NavbarListItemButton, ProfileAvatarSpan, ProfileAvatar, RouterLink, StyledAppBar, AppBarBox, StyledDrawer } from './NavbarStyles';
import { useLocation, useNavigate } from 'react-router-dom';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { HOME_ROUTE } from '../../constants/appRoutes';


function ProfileNameWithAvatar() {
    return(
        <ProfileAvatarSpan>
            <ProfileAvatar src={PersonalDetails.profilePicture} alt='profile-pic'/>
            { PersonalDetails.displayName }
        </ProfileAvatarSpan>
    )
}

export default function Navbar(props: any) {
    const navigate = useNavigate();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [navbarItemsArr, setNavbarItemsArr] = React.useState(NavbarItems);
    const location = useLocation();
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0
    });

    const updateNavbarItems = (currentRoute: string) => {
        for (const item of navbarItemsArr) {
            item.active = false;
            if (item.route === currentRoute) {
                item.active = true;
            }
        }
        setNavbarItemsArr([...navbarItemsArr]);
    }

    React.useEffect(() => {
        updateNavbarItems(location.pathname);
    }, [location])

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    // mobile view start...
    const drawer = (
        <Box sx={{ width: 'auto' }} >
            <Typography variant='h6' sx={{ paddingLeft: 2, my: 3, mt: 5 }}>
                <Grid container spacing={2}>
                    <Grid item xs={9.4} sm={10.6}>
                        <ProfileNameWithAvatar key='profile_avatar_sm'/>
                    </Grid>
                    <Grid item xs={1} sm={1}>
                        <IconButton key='profile_close_sm' sx={{ marginTop: '7px' }} aria-label="close-menu" onClick={handleDrawerToggle}>
                            <CloseOutlinedIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Typography>
            <Divider />
            <List>
                {navbarItemsArr.map((item) => (
                    <ListItem key={item.name} disablePadding>
                        <RouterLink key={`route_${item.name}_sm`} to={item.route}>
                            <NavbarListItemButton key={`list_item_button_${item.name}_sm`} active={item.active.toString()} onClick={() => {handleDrawerToggle()}}>
                                <ListItemIcon>{item.icon}</ListItemIcon>
                                <ListItemText primary={item.name}  />
                            </NavbarListItemButton>
                        </RouterLink>
                    </ListItem>
                ))}
            </List>
        </Box>
    );
    // mobile view end...

    return (
        <Box sx={{ display: 'flex' }} >
            <StyledAppBar elevation={ trigger ? 4 : 0} trigger={trigger.toString()}>
                <Container maxWidth="lg">
                    <Toolbar sx={{ padding: '0' }}>
                        <Typography variant='h6' component='div' onClick={() => navigate(HOME_ROUTE)}
                            sx={{ flexGrow: 1, height: '100px', display: 'inline-flex', cursor: 'pointer'}}>
                            <ProfileNameWithAvatar key='profile_avatar'/>
                        </Typography>
                        <AppBarBox>
                            {navbarItemsArr.map((item, idx) => (
                                <RouterLink key={`route_${item.name}_md`} to={item.route}>
                                    <AppButton id={`${item.name}_${idx}`} name={item.name} active={item.active} 
                                        handleOnClick={() => {}} startIcon={item.icon}/>
                                </RouterLink>
                            ))}
                        </AppBarBox>
                        <IconButton color='inherit' key='menu_icon_btn' aria-label='open drawer' edge='start'
                            onClick={handleDrawerToggle} sx={{ mr: 2, display: { md: 'none' } }}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Toolbar>
                </Container>
            </StyledAppBar>
            <Box component='nav'>
                <StyledDrawer anchor='top' variant='temporary' open={mobileOpen} key='drawer'
                    onClose={handleDrawerToggle}  ModalProps={{ keepMounted: true }}>
                    {drawer}
                </StyledDrawer>
            </Box>
            <Box component='main' sx={{ flexGrow: 1, paddingTop: '150px' }}>
                <Container maxWidth="lg">
                    {props.appRoutes}
                </Container>
            </Box>
        </Box>
    );
}
