import Grid from "@mui/material/Grid";
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import { PrimaryDetail, ResumePrimaryDetailsProps } from "../../../types/props/resumePrimaryDetailsProps";
import {
    PrimaryDetailsDesc, PrimaryDetailsDiv, PrimaryDetailsBorder, PrimaryDetailsTimeline,
    PrimaryDetailsTitle, PrimaryDetailSubtitle
} from "../resumeStyles";
import { ResumeDetails } from "../../../data/resumeDetails";


function PrimaryDetailsSection(props: ResumePrimaryDetailsProps) {
    return (
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ paddingTop: '0' }}>
            <h2>
                <props.icon sx={{ width: '28px', height: '28px', verticalAlign: 'sub', marginRight: '1px' }} color='primary' /> {props.section}
            </h2>
            <>
                {
                    props.details?.map(data =>
                        <PrimaryDetailsDiv key={`primary_details_${data.id}`}>
                            <PrimaryDetailsBorder />
                            <PrimaryDetailsTitle>{data.title}</PrimaryDetailsTitle>
                            <PrimaryDetailSubtitle>{data.subtitle}</PrimaryDetailSubtitle>
                            <PrimaryDetailsDesc>{data.description}</PrimaryDetailsDesc>
                            <PrimaryDetailsTimeline>{data.timeline}</PrimaryDetailsTimeline>
                        </PrimaryDetailsDiv>
                    )
                }
            </>
        </Grid>
    )
}


export default function ResumePrimaryDetails() {
    const educationDetails = () => {
        const details: PrimaryDetail[] = ResumeDetails.education.map(data => ({
            id: data.id,
            title: data.college,
            description: data.qualification,
            timeline: `${data.from} - ${data.to}`
        }));

        return details;
    }

    const workDetails = () => {
        const details: PrimaryDetail[] = ResumeDetails.work.map(data => {
            const fromDate = `${data.fromDate.toLocaleString('default', { month: 'short' })} ${data.fromDate.getFullYear()}`;
            const toDate = data.toDate ? `${data.toDate.toLocaleString('default', { month: 'short' })} ${data.toDate.getFullYear()}` : 'present';
            return {
                id: data.id,
                title: data.company,
                subtitle: data.city,
                description: data.designation,
                timeline: `${fromDate} - ${toDate}`
            }
        });

        return details;
    }

    return (
        <Grid container spacing={2}>
            <PrimaryDetailsSection section='Experience' icon={BusinessCenterOutlinedIcon} details={workDetails()} />
            <PrimaryDetailsSection section='Education' icon={SchoolOutlinedIcon} details={educationDetails()} />
        </Grid>
    )
}
