import { styled } from "@mui/material/styles";


export const PicDiv = styled('div')<{noMargin?: string}>(({ noMargin }) => ({
    background: '#80DCE3',
    borderRadius: '35px',
    width: '260px',
    height: '400px'
}));


export const Img = styled('img')<{noMargin?: string}>(({ noMargin }) => ({
    padding: '0 2px 20px 15px',
    borderRadius: '45px',
    float:'right',
    filter: 'drop-shadow(10px 10px 10px #1E1E1E)'
}));


export const PersonalInfoIcon = styled('span')(() => ({
    display: 'inline-flex',
    justifyContent: 'center',
    height: '48px',
    width: '46px',
    backgroundColor: '#272727',
    borderRadius: '50%',
    marginRight: '10px',
    'svg': {
        display: 'flex',
        alignItems: 'center',
        height: '48px'
    }
}));

export const AboutMeDesc = styled('p')(() => ({
    fontSize: '16px',
    color: '#A6A6A6',
    marginBottom: '0'
}));


export const PersonalInfoDiv = styled('div')(() => ({
    display: 'inline-flex',
    letterSpacing: '0.5px'
}));


export const PersonalInfoName = styled('p')(() => ({
    fontSize: '14px',
    color: '#A6A6A6',
    margin: '0',
    display: 'block'
}));


export const PersonalInfoValue = styled('h6')(() => ({
    fontSize: '15px',
    margin: '0'
}));


export const SectionHeader = styled('h2')(() => ({
    marginTop: '0',
    marginBottom: '0'
}));


export const AboutMeDiv = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        marginLeft: '80px'
    }
}));
