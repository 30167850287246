import Grid from "@mui/material/Grid";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import React from "react";
import { AppProgressBarProps } from "../../../types/props/appProgressBarProps";


const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: '#0093E9',
        backgroundImage: 'linear-gradient(270deg, #0093E9 0%, #80D0C7 100%)'
    }
}));


const ProgressBarLabelGrid = styled(Grid)(() => ({ 
    color: '#A6A6A6',
    marginTop: '20px',
    marginBottom: '7px',
    display: 'inline'
}));


const ProgressBarPercentGrid = styled(Grid)(() => ({
    color: '#A6A6A6',
    fontWeight: '700',
    marginTop: '20px',
    textAlign: 'end',
    paddingRight: '10px'
}));


export default function AppProgressBar(props: AppProgressBarProps) {

    const [progress, setProgress] = React.useState(0);

    React.useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= props.progress ? prevProgress : prevProgress + 1));
        });

        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <div style={{ flexGrow: 1 }} key={`progress_box_${props.appKey}`}>
            <Grid container key={`progress_grid_${props.appKey}`}>
                <ProgressBarLabelGrid item xs={10} sm={10} md={11} lg={11} xl={11}>
                    {props.label}
                </ProgressBarLabelGrid>
                <ProgressBarPercentGrid item xs={2} sm={2} md={1} lg={1} xl={1}>
                    {props.progress}%
                </ProgressBarPercentGrid>
            </Grid>
            <StyledLinearProgress key={`progress_${props.appKey}`} variant={props.isDeterminate ? "determinate" : undefined} value={progress} />
        </div>
    )
}
