import AppCard from "../common/app-card/AppCard";
import ResumeBody from "./body/ResumeBody";


export default function Resume() {
    return(
        <>
            <AppCard title="Resume" body={<ResumeBody />}></AppCard>
        </>
    )
}
