import Grid from "@mui/material/Grid";
import { PersonalDetails } from "../../data/personalDetails";
import AppCard from "../common/app-card/AppCard";
import { PicDiv, Img, PersonalInfoIcon, PersonalInfoDiv, PersonalInfoName,
        PersonalInfoValue, AboutMeDesc, SectionHeader, AboutMeDiv } from "./aboutMeStyles";
import { aboutMeDescription, ABOUT_ME_DESCRIPTION_SECTION_HEADING,
        ABOUT_ME_PERSONAL_DETAILS_SECTION_HEADING, personalInfoData } from "../../data/aboutMeData";


function AboutMeBody() {
    return(
        <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <PicDiv key={'profile_img_2'}>
                    <Img height='420' width='270' src={PersonalDetails.profilePicture2} alt='profile-pic-2'/>
                </PicDiv> 
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <AboutMeDiv>
                    <SectionHeader> {ABOUT_ME_DESCRIPTION_SECTION_HEADING} </SectionHeader>
                    <div>
                        {
                            aboutMeDescription.map((data, idx) => 
                                <AboutMeDesc key={`desc_${idx}`}>
                                    {data}
                                </AboutMeDesc>
                            )
                        }
                    </div>
                    <h2> {ABOUT_ME_PERSONAL_DETAILS_SECTION_HEADING} </h2>
                    <Grid container spacing={2}>
                        {
                            personalInfoData.map((data) => 
                                <Grid item key={`grid_item_${data.name}`} xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <PersonalInfoDiv>
                                        <PersonalInfoIcon>
                                            <data.icon color='primary'/>
                                        </PersonalInfoIcon>
                                        <div>
                                            <PersonalInfoName>{data.name}</PersonalInfoName>
                                            <PersonalInfoValue>{data.value}</PersonalInfoValue>
                                        </div>
                                    </PersonalInfoDiv>
                                </Grid>
                            )
                        }
                    </Grid>
                </AboutMeDiv>
            </Grid>
        </Grid>
    )
}


export default function AboutMe() {
    return(
        <>
            <AppCard title="About Me" body={<AboutMeBody/>}></AppCard>
        </>
    )
}
