import { ContactData } from '../types/contactData';
// import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
// import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { primaryInfo } from './personalInfo';


export const CONTACT_FORM_HEADING = 'Please feel free to leave a message...';

export const ContactDetails: ContactData[] = [
    // {
    //     id: 1,
    //     name: 'Phone',
    //     icon: PhoneInTalkOutlinedIcon,
    //     values: [ primaryInfo.mobileNumber ]
    // },
    {
        id: 2,
        name: 'Email',
        icon: AlternateEmailOutlinedIcon,
        values: [ 'nikhil.avula997@gmail.com' ]
    },
    // {
    //     id: 3,
    //     name: 'Address',
    //     icon: LocationOnOutlinedIcon,
    //     values: [ 'Bodhan, Nizamabad, Telangana, India - 503185' ]
    // },
    {
        id: 4,
        name: 'LinkedIn',
        icon: LinkedInIcon,
        values: [ 'nikhilsaikumar' ]
    },
]