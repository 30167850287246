import Grid from "@mui/material/Grid";
import { ContactDetails } from "../../data/contactDetails";
import AppCard from "../common/app-card/AppCard";
import { ContactDiv, ContactTitle, ContactValue } from "./contactStyles";
// import ContactForm from "./ContactForm";


function ContactBody() {
    return(
        <Grid container spacing={1} alignItems="center" justifyContent="center">
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                {
                    ContactDetails.map((contactInfo) => 
                        <ContactDiv key={`contact_${contactInfo.id}`} noMargin={`${contactInfo.id === 1}`}>
                            <ContactTitle>
                                <contactInfo.icon sx={{ verticalAlign: 'sub' }} color='primary'/> {contactInfo.name}
                            </ContactTitle>
                            
                            {
                                contactInfo.values.map((value, idx) =>
                                    <ContactValue key={`contact_val_${idx}`}>
                                        {value}
                                    </ContactValue>
                                )
                            }
                        </ContactDiv>
                    )
                }
            </Grid>
            {/* <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <ContactForm></ContactForm>
            </Grid> */}
        </Grid>
    )
}


export default function Contact() {
    return(
        <>
            <AppCard title="Contact" body={<ContactBody/>}></AppCard>
        </>
    )
}
