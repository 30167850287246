import { styled } from "@mui/material/styles";


export const ContactDiv = styled('div')<{noMargin?: string}>(({ noMargin }) => ({
    background: '#212425',
    borderRadius: '16px',
    marginTop: noMargin === 'true' ? '0px' : '24px',
    padding: '30px'
}));


export const ContactTitle = styled('h2')(() => ({
    margin: '0'
}));


export const ContactValue = styled('div')(() => ({
    color: '#A6A6A6',
    paddingLeft: '25px',
    marginTop: '8px',
    wordWrap: 'break-word'
}));


export const ContactFromDiv = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        marginLeft: '80px'
    },
    [theme.breakpoints.down('md')]: {
        marginTop: '24px'
    },
    border: '2px solid rgba(0, 147, 233, 0.1)',
    borderRadius: '16px',
    padding: '25px 25px 0 25px'
}));


export const ContactFormMessage = styled('h2')(() => ({
    color: '#A6A6A6',
    margin: '0'
}));


export const ContactElementDiv = styled('div')(() => ({
    marginTop: '25px'
}));


export const ContactActionDiv = styled('div')(() => ({
    marginTop: '35px',
    marginBottom: '25px'
}));
